/* eslint-disable no-unused-vars */
import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {TextDecrypt} from "../content/TextDecrypt";
import {FirstName, LastName} from "../../utils/getName";
import HTMLReactParser from "html-react-parser";

import './About.css';

import profile from '../../assets/profile.png';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "auto",
    },
}));

export const About = () => {
    const classes = useStyles();
    const greetings = "Hello there!";
    const aboutme = `I'm ${FirstName} ${LastName}, a skilled software engineer with over 16 years of professional experience. 
    I'm passionate for building and delivering the best quality software and always looking for new challenges. <br/>
    My interests are closely related to software development and delivery, CI/CD, all sorts of automation and 
    cloud computing. <br/>
    I hold Candidate of Sciences degree in computer science (Russian PhD analogue). I used to be a senior 
    lecturer at the Saint Petersburg State Institute of Technology. <br/>

    I'm happily married and have wonderful kids, who do not allow me to oversleep for work.`;

    return (
        <section id="about">
            <Container component="main" className={classes.main} maxWidth="md">
                <div className="about">
                    <div className="_img"
                         style={{
                             background: "url(" + profile + ")",
                             backgroundSize: 'contain',
                             backgroundPosition: 'center',
                             backgroundRepeat: 'no-repeat',
                         }}
                    >
                    </div>
                    <div className="_content_wrapper">
                        <Typography component='h2' variant="h5">
                            <TextDecrypt text={`${greetings}`}/>
                        </Typography>
                        <p className="aboutme">
                            {HTMLReactParser(aboutme)}
                        </p>
                        {/*<a href="#contact" className="contact-btn">*/}
                        {/*    <i className="fas fa-terminal"></i>*/}
                        {/*    <Typography component='span'> Send me a message.</Typography>*/}
                        {/*</a>*/}
                    </div>
                </div>
            </Container>
        </section>
    );
};
