/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import HTMLReactParser from "html-react-parser";

import './Works.css';

// Import ../../assets/recentprojects/
import PersonalWebsite from '../../assets/recentprojects/personal-website.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'smecsia.com',
      description: `
        This page is built upon the following technologies: <a href="https://crystal-lang.org">Crystal</a> and
        <a href="https://reactjs.org">React JS</a>. 
        Static assets are built into the statically linked binary wrapped into the 
        <a href="https://github.com/GoogleContainerTools/distroless">Distroless</a> image.
        <br/>
        It is deployed as a <a href="https://cloud.yandex.com/en/services/serverless-containers">Serverless Container</a> in 
        <a href="https://cloud.yandex.com">Yandex Cloud</a>.
      `,
      alter: '',
      image: `${PersonalWebsite}`,
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper">
              <img src={ project.image } alt={ project.alter }/>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={ project.id + '. ' + project.title } />
              </h3>
              <p className="description">
                { HTMLReactParser(project.description) }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
