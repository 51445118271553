import React, {useState, useEffect, useRef} from "react";
import CELLS from "vanta/dist/vanta.cells.min";
import WAVES from "vanta/dist/vanta.waves.min";
import * as THREE from "three";

class VantaBg extends React.Component {
    constructor() {
        super();
        this.vantaRef = React.createRef();
    }

    componentDidMount() {
        this.vantaEffect = WAVES({
            el: this.vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x200088,
            THREE: THREE,
        })
        // this.vantaEffect = CELLS({
        //     el: this.vantaRef.current,
        //     mouseControls: true,
        //     touchControls: true,
        //     gyroControls: false,
        //     minHeight: 200.00,
        //     minWidth: 200.00,
        //     scale: 1.00,
        //     color1: 0x203187,
        //     color2: 0x61289b,
        //     size: 3.20,
        //     speed: 1.30,
        //     THREE: THREE,
        // })
    }

    componentWillUnmount() {
        if (this.vantaEffect) {
            this.vantaEffect.destroy();
        }
    }

    render() {
        return (
            <div style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                zIndex: -1000,
                opacity: "0.7",
                WebkitMaskImage: "-webkit-gradient(linear, left top,left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
            }} ref={this.vantaRef}></div>
        );
    }
}

export default VantaBg;