import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
        backgroundColor: "#bdf2ff",
        opacity: 0.7,
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="50 -10 220 220"
            className={classes.svgHover}
        >
            <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M1420 2690 c-6 -12 -9 -35 -7 -53 l4 -32 1 35 c4 73 59 34 63 -44 1
-12 5 -3 10 19 5 22 11 51 15 64 l5 25 -20 -25 c-13 -14 -20 -18 -19 -9 2 10
-5 15 -20 14 -15 0 -21 4 -18 13 8 20 -2 15 -14 -7z"/>
                <path d="M1374 2677 c-2 -7 0 -24 5 -38 5 -13 10 -17 11 -9 0 8 3 25 6 38 7
24 -13 32 -22 9z"/>
                <path d="M1692 2679 c2 -8 14 -19 25 -24 11 -6 18 -16 15 -24 -3 -8 0 -11 7
-7 18 12 13 23 -20 47 -21 15 -30 17 -27 8z"/>
                <path d="M1744 2682 c3 -6 27 -23 54 -38 26 -15 50 -32 54 -38 4 -5 10 3 13
20 4 16 8 35 11 42 2 7 -1 10 -6 7 -6 -3 -10 -17 -10 -30 0 -18 -4 -23 -15
-19 -8 4 -13 10 -10 14 3 5 0 12 -6 16 -8 4 -9 3 -5 -4 11 -18 0 -14 -45 14
-23 14 -38 21 -35 16z"/>
                <path d="M1526 2647 c-5 -21 -6 -41 -2 -44 12 -12 17 5 14 43 l-4 39 -8 -38z"/>
                <path d="M1647 2664 c2 -7 0 -22 -7 -33 -6 -12 -7 -21 -2 -21 4 0 15 15 24 33
15 31 14 33 -2 33 -10 0 -16 -6 -13 -12z"/>
                <path d="M2107 2565 c3 -33 7 -62 9 -64 7 -8 2 74 -6 99 -5 16 -6 2 -3 -35z"/>
                <path d="M1234 2598 c-5 -7 -3 -8 6 -3 10 6 11 4 5 -11 -4 -10 -8 -29 -8 -41
1 -13 -3 -21 -7 -18 -5 3 -11 1 -15 -5 -4 -6 -11 -8 -16 -5 -5 3 -6 -1 -2 -11
5 -14 7 -15 15 -1 5 9 15 14 24 10 12 -4 15 -1 12 13 -3 10 1 31 8 45 13 25
10 39 -7 39 -4 0 -11 -5 -15 -12z"/>
                <path d="M1155 2426 c-13 -14 -15 -46 -4 -46 5 0 9 -5 9 -11 0 -5 -5 -7 -11
-3 -6 4 -10 -1 -8 -12 5 -33 -11 -92 -26 -98 -19 -7 -19 -106 -1 -226 7 -47
12 -88 10 -91 -2 -3 11 -19 29 -35 l32 -29 -29 33 c-37 40 -20 43 19 2 15 -16
32 -27 36 -24 4 2 11 -12 15 -33 4 -21 8 -46 10 -56 6 -33 58 -126 72 -129 15
-3 52 -93 59 -145 3 -18 10 -33 17 -33 7 0 6 -4 -3 -9 -9 -6 -10 -11 -2 -15
26 -17 2 -62 -39 -72 -22 -5 -39 -18 -34 -25 3 -5 -2 -9 -11 -9 -9 0 -13 -4
-10 -10 3 -5 0 -10 -8 -10 -8 0 -24 -9 -37 -20 -26 -23 -35 -26 -24 -8 5 7 -4
3 -18 -8 -17 -13 -43 -21 -73 -22 -28 -2 -64 -12 -90 -26 -24 -14 -46 -22 -49
-19 -3 3 -12 0 -20 -6 -8 -7 -19 -10 -24 -7 -5 3 -7 -7 -5 -22 2 -20 1 -23 -5
-9 -8 18 -9 18 -22 1 -13 -16 -13 -17 0 -9 8 4 5 -3 -8 -17 -12 -13 -19 -27
-16 -31 9 -8 -37 -97 -47 -91 -5 3 -6 -2 -3 -10 3 -9 1 -16 -5 -16 -6 0 -11
-7 -11 -16 0 -9 -5 -12 -12 -8 -6 4 -8 3 -5 -4 8 -12 -23 -72 -38 -72 -5 0 -4
-4 3 -9 11 -7 9 -18 -9 -57 -12 -27 -26 -65 -31 -84 -17 -62 -35 -170 -30
-170 4 0 1 -5 -5 -11 -6 -6 -9 -18 -6 -27 4 -9 2 -13 -4 -9 -6 4 -11 1 -10 -6
0 -6 -1 -21 -2 -32 -1 -17 -3 -18 -12 -5 -9 13 -10 12 -5 -3 3 -10 2 -25 -4
-35 -5 -9 -11 -28 -14 -42 -3 -14 -20 -41 -38 -60 -18 -18 -26 -30 -18 -26 9
4 8 2 -2 -7 -10 -8 -15 -17 -11 -20 3 -4 1 -7 -6 -7 -9 0 -11 -8 -7 -24 4 -17
-7 -52 -39 -118 -45 -93 -67 -168 -48 -168 5 0 24 37 41 83 49 126 87 187 118
187 14 0 23 -4 20 -9 -4 -5 4 -7 16 -4 19 4 20 3 8 -6 -13 -9 -12 -11 5 -9 37
3 56 -2 50 -12 -3 -6 1 -7 10 -4 9 4 14 9 11 12 -14 13 -116 49 -140 47 -14 0
-26 1 -26 3 0 3 16 22 36 44 36 40 74 115 74 146 0 10 9 34 19 53 11 20 23 65
27 100 8 71 82 306 108 341 46 65 66 99 76 138 13 50 49 86 99 100 20 6 45 17
55 25 11 8 34 15 51 15 42 0 130 34 176 67 54 39 94 66 114 78 16 10 16 18
-15 128 -23 82 -41 128 -62 155 -42 53 -67 103 -73 154 -11 77 -15 88 -33 88
-56 0 -88 73 -98 224 -6 83 -5 93 15 116 l21 25 25 -28 c19 -21 25 -39 25 -73
0 -24 -5 -44 -11 -44 -5 0 -7 -5 -4 -10 4 -6 1 -17 -5 -25 -14 -17 -10 -57 7
-62 7 -3 9 3 6 16 -3 12 1 35 8 53 7 18 12 43 11 57 -1 17 1 22 6 13 7 -10 11
-10 21 1 9 12 8 15 -6 13 -14 -1 -17 5 -15 33 3 28 1 33 -12 28 -10 -4 -16 -1
-16 8 0 8 -4 15 -9 15 -11 0 -21 39 -28 105 -6 58 -5 56 -18 41z"/>
                <path d="M1401 2289 c-13 -10 -29 -19 -36 -19 -7 0 -19 -7 -26 -17 -13 -15
-11 -16 13 -10 69 18 93 33 80 54 -5 8 -15 5 -31 -8z"/>
                <path d="M1465 2290 c16 -7 17 -9 3 -9 -9 -1 -19 -5 -22 -9 -5 -9 2 -11 62
-13 20 0 42 -5 49 -11 7 -5 17 -7 23 -3 5 3 10 1 10 -4 0 -12 29 -14 54 -5 9
3 16 11 16 16 0 7 -4 7 -13 0 -8 -7 -26 -4 -62 13 -28 12 -70 25 -95 28 -30 4
-38 3 -25 -3z"/>
                <path d="M1833 2257 c-43 -12 -67 -37 -96 -96 -22 -44 -21 -51 4 -51 15 0 20
5 17 23 -3 19 2 22 30 22 29 0 48 15 20 15 -7 0 -5 5 5 11 26 15 21 32 -8 25
-14 -4 -25 -2 -25 4 0 11 2 12 40 16 78 8 151 5 179 -9 29 -14 30 -14 21 3
-23 43 -104 59 -187 37z"/>
                <path d="M1478 2239 c-54 -6 -128 -41 -128 -60 0 -13 4 -12 56 16 26 14 48 25
49 25 2 0 6 -11 9 -25 7 -27 30 -32 56 -14 12 9 12 10 -4 5 -14 -5 -17 -2 -12
10 3 8 6 19 6 22 0 4 5 0 10 -8 8 -13 10 -12 10 3 0 22 17 22 24 -1 7 -20 42
-27 52 -10 4 7 2 8 -4 4 -7 -4 -12 -3 -12 3 0 22 -54 37 -112 30z"/>
                <path d="M1158 2234 c-5 -4 -8 -32 -7 -63 0 -47 2 -52 10 -31 11 27 8 106 -3
94z"/>
                <path d="M2180 2230 c0 -5 4 -10 10 -10 5 0 14 -15 19 -32 7 -27 9 -28 10 -10
1 13 -4 32 -9 43 -11 20 -30 26 -30 9z"/>
                <path d="M1844 2206 c-14 -36 42 -76 62 -44 4 6 3 8 -4 4 -6 -4 -10 1 -9 12 1
25 38 17 45 -10 4 -15 7 -16 20 -6 11 9 13 9 9 1 -4 -6 -3 -14 2 -17 7 -5 21
14 21 28 0 6 -77 35 -108 40 -23 4 -34 1 -38 -8z"/>
                <path d="M1546 2160 c-4 -15 -9 -20 -13 -12 -6 14 -29 16 -38 2 -3 -6 1 -7 10
-4 11 4 14 2 9 -9 -6 -17 11 -18 36 -2 13 7 13 6 2 -7 -7 -9 -24 -19 -37 -22
-14 -4 -25 -11 -25 -16 0 -15 56 9 81 36 18 19 19 24 6 24 -8 0 -17 8 -19 18
-3 13 -6 11 -12 -8z"/>
                <path d="M2177 2173 c-10 -9 -7 -74 3 -98 6 -14 9 -2 9 43 1 62 0 68 -12 55z"/>
                <path d="M2220 2133 c-15 -55 -22 -119 -13 -109 3 3 7 18 9 33 1 16 8 48 14
72 6 24 9 45 7 48 -3 2 -10 -18 -17 -44z"/>
                <path d="M1711 2082 c-7 -13 -5 -28 6 -50 21 -43 37 -30 18 15 -19 44 -19 47
1 39 8 -3 12 -2 9 4 -9 15 -23 12 -34 -8z"/>
                <path d="M2120 2068 c0 -18 -3 -44 -7 -57 -4 -18 -3 -22 6 -17 7 5 21 0 32
-10 22 -20 26 -10 4 12 -15 16 -21 39 -7 31 4 -3 8 6 8 19 1 13 -2 21 -7 18
-5 -3 -9 2 -9 10 0 8 -4 18 -10 21 -6 4 -10 -7 -10 -27z"/>
                <path d="M1239 2055 c-3 -11 0 -45 7 -75 10 -47 12 -50 12 -20 1 52 -13 120
-19 95z"/>
                <path d="M1780 2011 c0 -6 -3 -10 -7 -8 -12 3 -13 -11 -3 -35 9 -23 9 -23 10
2 1 23 2 23 11 7 6 -10 8 -29 5 -42 -5 -19 -3 -23 9 -19 9 4 15 1 15 -7 1 -10
3 -10 10 2 5 8 8 22 7 31 -3 18 -57 84 -57 69z"/>
                <path d="M2190 1991 c0 -32 -29 -90 -50 -101 -12 -6 -29 -9 -39 -5 -15 5 -20
-3 -28 -44 -9 -45 -2 -145 13 -193 10 -32 74 -92 102 -95 15 -2 66 -23 114
-48 48 -25 94 -45 103 -45 9 0 93 -38 188 -84 230 -112 268 -152 313 -329 29
-115 61 -399 49 -436 -3 -12 -8 -61 -9 -111 -2 -52 -8 -96 -15 -104 -9 -11
-14 -72 -16 -206 -2 -160 0 -190 12 -190 10 0 12 6 8 20 -3 11 -2 20 2 20 8 0
10 116 3 130 -1 3 -1 10 0 15 1 6 4 46 5 90 1 44 8 96 14 115 11 35 25 219 22
295 0 22 -5 69 -11 105 -5 36 -12 92 -15 125 -12 131 -24 183 -59 250 -9 17
-16 35 -16 42 0 6 -5 15 -12 19 -7 5 -9 2 -4 -7 4 -8 1 -6 -8 4 -8 11 -12 25
-9 33 3 8 -5 18 -21 24 -14 5 -25 14 -24 19 2 5 -1 10 -7 11 -5 2 -27 14 -47
26 -21 13 -42 24 -48 24 -5 0 -10 5 -10 11 0 6 -7 8 -16 5 -8 -3 -12 -2 -9 4
3 6 0 11 -7 11 -28 3 -35 5 -94 36 -34 18 -64 29 -68 26 -3 -4 -6 -1 -6 5 0 6
-17 16 -37 22 -21 7 -42 16 -47 20 -5 5 -15 6 -22 3 -8 -3 -14 0 -14 7 0 7 -7
10 -16 6 -8 -3 -12 -2 -9 4 3 6 -1 11 -9 11 -19 2 -26 4 -46 12 -43 18 -78 29
-105 34 -59 9 -95 81 -95 188 0 63 18 105 46 105 8 0 14 5 14 11 0 6 7 9 15 5
8 -3 14 -2 13 2 -4 13 13 52 20 48 4 -2 7 13 7 35 0 21 -3 39 -7 39 -5 0 -8
-8 -8 -19z"/>
                <path d="M1508 1965 c-19 -16 -24 -26 -16 -31 6 -4 19 2 29 14 12 15 19 18 22
10 2 -7 8 -10 13 -5 4 4 1 14 -7 22 -12 12 -18 10 -41 -10z"/>
                <path d="M2094 1945 c-4 -16 -2 -31 6 -38 10 -10 12 -8 6 7 -5 14 -2 17 9 12
8 -3 15 -1 15 4 0 6 -5 10 -11 10 -5 0 -8 4 -4 9 3 5 1 12 -5 16 -5 3 -12 -6
-16 -20z"/>
                <path d="M1613 1932 c-13 -2 -23 -8 -23 -13 0 -12 57 -12 64 0 7 11 -13 18
-41 13z"/>
                <path d="M1715 1920 c-3 -5 -18 -10 -32 -10 -21 0 -23 -2 -13 -15 8 -10 17
-12 28 -6 9 5 26 5 40 0 15 -6 21 -5 17 1 -3 6 2 10 12 10 14 1 13 4 -7 15
-30 17 -37 18 -45 5z"/>
                <path d="M1653 1872 c-8 -5 -8 -14 1 -31 l13 -24 5 27 c6 29 -1 39 -19 28z"/>
                <path d="M1470 1822 c-28 -13 -18 -27 20 -28 21 0 70 -6 107 -13 38 -8 94 -11
126 -9 31 2 58 1 59 -3 4 -10 58 1 58 12 0 5 -17 9 -37 10 -177 4 -289 15
-298 30 -7 10 -14 10 -35 1z"/>
                <path d="M2018 1628 c-30 -75 -31 -78 -17 -78 7 0 10 3 6 6 -3 3 3 23 14 45
12 21 19 45 17 54 -2 8 -11 -4 -20 -27z"/>
                <path d="M1420 1612 c0 -10 66 -63 117 -95 29 -19 53 -26 83 -24 31 1 38 4 28
12 -12 8 -12 15 -2 35 15 28 13 33 -10 24 -9 -4 -20 -19 -23 -35 -3 -16 -8
-29 -11 -29 -11 0 -97 54 -104 66 -10 15 -78 56 -78 46z"/>
                <path d="M1730 1567 c-14 -7 -26 -21 -28 -32 -3 -19 -2 -19 11 -2 8 9 21 17
30 17 9 0 18 4 22 9 9 16 -10 20 -35 8z"/>
                <path d="M2145 1515 c3 -11 2 -14 -1 -7 -10 21 -26 13 -19 -9 5 -14 4 -19 -4
-15 -7 5 -8 1 -4 -11 6 -16 5 -17 -12 -3 -16 14 -17 13 -11 -7 4 -12 2 -24 -3
-28 -7 -3 -8 0 -5 9 3 9 1 16 -5 16 -6 0 -11 -5 -11 -10 0 -6 -12 -23 -26 -37
-18 -18 -22 -29 -15 -36 13 -13 80 49 131 119 l31 44 -25 -2 c-21 -2 -25 -7
-21 -23z"/>
                <path d="M1445 1365 c4 -16 7 -60 6 -97 -1 -44 4 -78 14 -97 19 -37 31 -45 39
-25 3 8 2 13 -3 10 -16 -10 -32 48 -37 133 -2 47 -9 90 -15 96 -9 7 -10 2 -4
-20z"/>
                <path d="M1986 1355 c-9 -14 -21 -25 -27 -25 -6 0 -24 -14 -41 -31 -17 -16
-37 -28 -44 -25 -8 3 -23 -7 -34 -22 -11 -15 -36 -36 -55 -46 -19 -11 -35 -23
-35 -27 0 -10 49 16 142 77 41 27 66 50 62 56 -5 7 -2 8 5 4 14 -9 53 22 45
36 -3 4 -2 7 3 6 4 -2 9 1 11 7 7 21 -16 14 -32 -10z"/>
                <path d="M2138 1358 c0 -7 -2 -18 -2 -24 -2 -19 -39 -94 -46 -94 -4 0 -11 -21
-15 -47 -3 -27 -13 -58 -21 -70 -15 -24 -3 -41 14 -20 6 6 18 39 27 72 10 33
28 78 41 101 24 42 32 94 14 94 -5 0 -11 -6 -12 -12z"/>
                <path d="M2383 1223 c-57 -55 -112 -126 -85 -110 18 12 15 -7 -7 -34 -29 -38
-47 -78 -80 -178 -37 -111 -40 -164 -5 -71 14 36 49 115 79 177 30 62 55 114
55 117 0 13 64 97 90 120 17 13 30 27 30 30 0 16 -23 1 -77 -51z"/>
                <path d="M2620 1223 c-25 -9 -64 -29 -88 -45 -23 -16 -47 -26 -54 -22 -6 4 -8
3 -5 -3 3 -5 -19 -34 -49 -64 -30 -29 -52 -59 -50 -66 3 -7 -1 -10 -7 -7 -15
5 -99 -128 -148 -235 -30 -67 -16 -59 21 11 44 84 144 228 204 294 54 60 121
106 181 124 22 7 49 16 60 21 40 15 -16 9 -65 -8z"/>
                <path d="M1601 1163 c-1 -6 -13 -16 -28 -22 -23 -9 -20 -9 22 -4 53 6 73 13
38 13 -12 0 -24 6 -26 13 -4 8 -6 8 -6 0z"/>
                <path d="M2518 1090 c-51 -48 -57 -72 -7 -29 13 11 34 29 47 40 19 17 30 39
18 39 -3 0 -28 -22 -58 -50z"/>
                <path d="M2555 1019 c-16 -11 -25 -19 -20 -19 6 0 -7 -10 -27 -23 -54 -33
-178 -149 -171 -160 3 -6 1 -7 -5 -3 -7 4 -18 -6 -27 -25 -9 -17 -34 -50 -55
-73 -33 -36 -68 -96 -56 -96 2 0 41 44 87 98 91 107 200 217 253 253 31 21 62
57 54 64 -2 1 -16 -6 -33 -16z"/>
                <path d="M2617 977 c-114 -91 -211 -179 -260 -236 -27 -31 -61 -71 -76 -89
-16 -18 -25 -35 -21 -37 5 -3 45 38 90 89 45 52 86 96 91 98 5 2 37 29 71 60
35 31 91 79 126 107 34 27 59 52 54 55 -5 3 -39 -18 -75 -47z"/>
                <path d="M2133 858 c-12 -54 -13 -508 -2 -508 3 0 6 6 6 13 1 6 6 35 11 62 7
33 7 76 1 125 -12 97 -13 207 -4 293 4 37 5 67 3 67 -2 0 -9 -24 -15 -52z"/>
                <path d="M2753 800 c3 -22 2 -42 -3 -45 -4 -2 -10 -18 -13 -35 -4 -17 -13 -30
-21 -30 -8 0 -25 -20 -37 -45 -12 -25 -32 -51 -43 -59 -12 -7 -36 -35 -54 -62
-19 -27 -25 -39 -14 -27 11 12 22 20 25 17 3 -3 10 4 17 15 6 12 15 19 20 16
5 -3 11 4 15 14 3 11 15 23 25 26 11 4 17 12 13 21 -3 7 0 14 6 14 6 0 11 10
11 23 0 18 2 19 10 7 6 -8 8 -28 5 -45 -5 -27 -4 -28 5 -7 5 13 7 35 4 48 -4
14 -2 24 5 24 5 0 15 19 22 43 6 23 15 47 20 54 14 17 30 75 18 68 -5 -4 -9
-15 -9 -26 0 -10 -5 -19 -11 -19 -7 0 -9 10 -5 25 4 16 2 25 -5 25 -8 0 -10
-13 -6 -40z"/>
                <path d="M1368 812 c-44 -29 -189 -231 -206 -287 -8 -27 -28 -68 -45 -90 -17
-22 -32 -42 -34 -44 -2 -2 -6 -1 -9 2 -8 7 34 176 48 193 17 23 -31 22 -81 -2
-40 -18 -51 -28 -50 -46 0 -13 3 -17 6 -10 6 16 83 56 91 47 7 -6 -3 -25 -51
-93 -19 -27 -27 -50 -25 -69 l3 -28 20 35 20 35 3 -42 c5 -64 24 -72 61 -24
17 21 31 43 31 48 0 5 23 31 51 58 52 52 153 194 164 231 3 14 0 24 -9 27 -11
4 -13 1 -9 -10 4 -10 2 -14 -4 -10 -6 4 -14 0 -17 -9 -4 -10 -2 -13 7 -8 6 4
0 -6 -14 -22 -15 -16 -24 -35 -21 -43 2 -8 0 -11 -6 -7 -6 3 -13 -2 -16 -12
-4 -10 -24 -36 -46 -58 -22 -21 -40 -47 -40 -56 0 -9 -4 -19 -8 -22 -5 -3 -8
7 -7 22 1 15 23 56 49 92 26 36 63 89 83 118 37 54 48 61 57 40 6 -17 36 19
36 44 0 22 1 22 -32 0z"/>
                <path d="M830 808 c-1 -49 88 -231 118 -243 15 -5 12 0 -34 65 -17 25 -43 79
-57 120 -14 41 -26 67 -27 58z"/>
                <path d="M2625 811 c-31 -13 -437 -421 -419 -421 10 0 237 219 295 285 27 31
69 65 79 65 3 0 14 14 23 30 10 17 25 30 33 30 8 0 14 5 14 10 0 6 -1 10 -2 9
-2 0 -12 -4 -23 -8z"/>
                <path d="M2166 734 c-7 -17 -8 -120 -2 -113 5 5 16 129 12 129 -2 0 -7 -7 -10
-16z"/>
                <path d="M2436 729 c-11 -10 -16 -22 -13 -26 4 -3 18 6 31 20 28 31 11 36 -18
6z"/>
                <path d="M2641 700 c-25 -54 -28 -85 -5 -52 9 13 13 26 10 29 -3 4 1 14 10 24
9 10 14 24 11 31 -2 7 -14 -7 -26 -32z"/>
                <path d="M1542 648 c-29 -39 -52 -67 -52 -62 -1 5 -6 0 -13 -11 -7 -12 -20
-21 -29 -22 -78 -4 -75 -2 -54 -18 13 -10 24 -11 37 -5 44 24 39 -3 -15 -76
-53 -70 -107 -104 -137 -86 -25 16 -24 1 1 -29 11 -14 19 -18 20 -11 0 7 5 10
11 7 16 -10 82 40 116 88 36 51 66 124 57 139 -4 6 -2 8 4 5 11 -8 62 47 62
66 0 7 4 6 10 -3 7 -11 10 -12 10 -2 0 8 7 29 16 48 28 59 10 47 -44 -28z"/>
                <path d="M2320 614 c-47 -47 -96 -97 -110 -110 -20 -20 -21 -24 -6 -18 28 10
209 191 204 204 -2 6 -41 -28 -88 -76z"/>
                <path d="M1792 656 c-48 -39 -72 -66 -96 -113 -9 -17 -29 -40 -46 -49 -21 -13
-26 -20 -17 -26 9 -6 8 -8 -3 -8 -36 0 -38 20 -8 96 27 69 29 94 9 94 -14 0
-63 -53 -72 -78 -5 -13 -13 -21 -18 -17 -6 3 -10 -3 -9 -12 0 -18 -43 -128
-56 -140 -3 -3 -6 -20 -6 -37 0 -45 14 -23 49 77 32 93 75 177 91 177 13 0 13
3 -10 -69 -19 -60 -18 -121 2 -121 5 0 6 5 2 12 -5 8 -2 9 9 5 9 -3 19 -2 22
3 4 6 17 10 31 10 14 0 23 4 19 9 -3 5 21 30 54 56 43 34 65 60 81 96 12 28
20 55 18 60 -2 4 -22 -7 -46 -25z m13 -26 c-11 -5 -15 -9 -8 -9 7 -1 10 -8 6
-17 -4 -12 -8 -13 -14 -3 -5 9 -13 2 -28 -21 -18 -30 -51 -50 -51 -31 0 20 73
91 93 90 21 0 21 -1 2 -9z"/>
                <path d="M1383 608 c-50 -43 -78 -74 -133 -143 -24 -31 -52 -61 -62 -66 -10
-5 -18 -17 -18 -25 0 -10 -6 -14 -14 -11 -10 4 -13 1 -10 -7 10 -26 52 -3 92
48 22 29 22 29 23 5 l1 -24 7 25 c14 44 89 150 106 150 4 0 16 11 27 25 11 14
25 25 33 25 7 0 18 7 25 15 25 31 -38 16 -77 -17z"/>
                <path d="M855 605 c4 -16 15 -48 26 -72 10 -23 19 -48 19 -55 0 -7 5 -20 10
-28 18 -26 0 39 -32 115 -16 39 -26 57 -23 40z"/>
                <path d="M2218 593 c-9 -10 -23 -35 -32 -57 -14 -33 -14 -37 -1 -26 8 7 15 17
15 22 0 6 9 23 21 40 24 32 21 48 -3 21z"/>
                <path d="M2760 568 c0 -23 3 -38 8 -34 4 4 7 23 7 42 0 19 -3 34 -7 34 -5 0
-8 -19 -8 -42z"/>
                <path d="M1909 564 c-8 -10 -41 -37 -74 -61 -33 -24 -59 -46 -57 -48 5 -11 1
-26 -6 -21 -4 2 -14 1 -22 -4 -12 -7 -12 -10 3 -16 25 -9 127 62 127 90 0 5
12 22 27 38 14 15 24 30 21 33 -3 3 -11 -2 -19 -11z"/>
                <path d="M2676 535 c-4 -14 -10 -23 -15 -20 -10 6 -23 -20 -14 -29 9 -8 31 19
39 48 3 14 4 26 1 26 -3 0 -8 -11 -11 -25z"/>
                <path d="M2457 524 c-21 -14 -36 -28 -33 -32 3 -3 17 4 29 16 13 12 26 20 29
17 3 -3 8 1 11 10 8 19 8 20 -36 -11z"/>
                <path d="M2732 513 c-6 -2 -9 -9 -6 -15 3 -5 -3 -18 -15 -29 -24 -23 -19 -48
6 -28 13 12 43 62 43 74 0 6 -10 5 -28 -2z"/>
                <path d="M810 496 c0 -3 14 -25 30 -50 17 -25 40 -66 51 -92 17 -38 19 -48 8
-55 -11 -7 -10 -9 3 -9 9 0 20 -5 24 -12 4 -7 3 -8 -4 -4 -7 4 -12 2 -12 -3 0
-6 9 -11 20 -11 11 0 20 4 20 8 0 5 -9 19 -20 32 -11 13 -20 29 -20 37 0 17
-76 149 -90 158 -6 3 -10 4 -10 1z"/>
                <path d="M1747 454 c-3 -3 -22 -6 -41 -6 -38 0 -43 -6 -23 -27 9 -11 9 -12 0
-7 -9 5 -11 2 -7 -8 7 -19 24 -21 24 -3 0 8 4 7 11 -3 9 -12 10 -11 6 7 -4 17
1 24 24 32 16 5 29 12 29 15 0 8 -15 8 -23 0z"/>
                <path d="M2505 440 c-4 -6 -11 -8 -16 -5 -5 4 -9 1 -9 -4 0 -16 36 -13 50 4
10 12 10 15 -3 15 -9 0 -19 -5 -22 -10z"/>
                <path d="M2660 434 c0 -8 -4 -13 -9 -10 -4 3 -26 -10 -47 -29 -21 -19 -42 -35
-46 -35 -4 0 -5 -7 -2 -15 4 -8 10 -15 16 -15 5 0 7 4 4 8 -3 5 7 15 21 23 58
31 106 89 73 89 -5 0 -10 -7 -10 -16z"/>
                <path d="M985 363 c29 -48 45 -67 52 -60 3 4 -6 20 -20 35 -15 16 -27 33 -27
40 0 7 -5 12 -11 12 -6 0 -3 -12 6 -27z"/>
                <path d="M953 258 c-26 -13 -32 -33 -7 -24 9 4 23 1 30 -5 12 -9 12 -15 2 -28
-12 -14 -11 -14 5 -2 15 12 20 10 40 -15 12 -16 21 -32 20 -36 -2 -5 1 -8 5
-8 7 0 11 -16 14 -57 1 -7 5 -13 10 -13 5 0 8 -6 6 -12 -3 -18 24 -58 39 -58
8 0 2 21 -17 59 -17 32 -30 67 -30 78 0 11 -8 30 -19 42 -10 12 -29 37 -42 56
-27 39 -25 38 -56 23z"/>
                <path d="M2259 256 c-95 -12 -149 -27 -149 -42 0 -2 9 0 19 6 11 5 85 15 165
22 119 10 162 10 229 -1 45 -8 92 -13 104 -13 13 0 21 -3 19 -7 -3 -4 11 -6
30 -6 19 1 34 -2 34 -5 0 -4 16 -6 35 -3 19 2 35 -1 35 -6 0 -5 11 -8 25 -5
26 5 35 -10 13 -20 -7 -3 -3 -3 9 0 18 4 22 2 17 -11 -4 -10 -13 -13 -26 -9
-15 4 -18 3 -13 -6 5 -9 4 -11 -4 -6 -6 4 -9 11 -6 16 3 5 1 11 -5 15 -6 4
-10 -5 -10 -19 0 -29 8 -31 61 -16 50 14 58 50 18 74 -36 21 -168 47 -234 46
-27 -1 -97 1 -155 4 -62 3 -148 0 -211 -8z"/>
                <path d="M1476 228 c-32 -24 -59 -79 -50 -102 9 -23 19 -20 47 14 23 27 46 42
33 21 -3 -4 1 -17 8 -27 14 -18 15 -18 36 1 24 22 55 75 43 75 -4 0 -19 -12
-34 -26 -35 -34 -51 -24 -43 27 7 44 1 47 -40 17z m24 -17 c0 -16 -42 -61 -52
-55 -5 4 2 19 18 35 30 31 34 34 34 20z"/>
                <path d="M756 225 c-3 -9 0 -15 10 -15 9 0 12 -5 8 -12 -5 -7 -3 -8 5 -4 7 5
10 14 7 21 -3 8 0 16 7 19 7 2 2 5 -9 5 -12 1 -24 -6 -28 -14z"/>
                <path d="M1197 205 c-19 -40 -45 -55 -58 -34 -7 11 -9 11 -9 -3 0 -9 7 -21 15
-26 10 -5 13 -15 9 -25 -9 -24 -21 -21 -39 11 l-16 27 6 -30 c3 -16 15 -39 26
-50 19 -19 21 -19 33 -2 8 9 17 36 22 60 l8 42 4 -57 c4 -77 22 -74 21 3 -1
44 3 66 15 77 15 15 16 13 16 -28 0 -67 9 -90 34 -90 16 0 29 12 45 40 25 45
21 49 -16 20 -14 -11 -32 -20 -40 -20 -15 0 -14 14 3 72 5 18 -29 43 -45 33
-5 -3 -11 0 -13 6 -2 6 -11 -6 -21 -26z"/>
                <path d="M1127 212 c-20 -22 -23 -39 -4 -25 14 11 27 11 46 -1 8 -5 7 1 -4 18
-21 31 -18 30 -38 8z"/>
                <path d="M806 211 c-3 -4 1 -17 9 -27 8 -10 15 -14 15 -8 0 5 -6 16 -12 24 -7
8 10 -6 37 -31 28 -25 60 -54 73 -64 12 -11 22 -25 22 -32 0 -7 4 -13 9 -13 6
0 18 -14 28 -30 18 -31 40 -42 29 -15 -11 30 -70 114 -77 110 -3 -3 -29 18
-57 45 -51 50 -66 58 -76 41z"/>
                <path d="M887 206 c-3 -7 8 -26 24 -42 34 -33 37 -25 8 22 -23 38 -25 39 -32
20z"/>
                <path d="M722 170 c9 -14 36 -47 61 -74 25 -27 43 -54 40 -59 -3 -6 -1 -7 5
-3 6 3 13 -3 17 -14 6 -18 25 -29 25 -14 0 11 -89 120 -126 154 -30 28 -34 30
-22 10z"/>
                <path d="M677 152 c-25 -28 -21 -53 18 -95 19 -21 35 -42 35 -48 0 -5 7 -9 15
-9 8 0 15 5 15 10 0 6 -4 10 -10 10 -5 0 -10 5 -10 11 0 6 -12 20 -26 31 -37
30 -49 66 -28 89 10 11 15 19 12 19 -3 0 -12 -8 -21 -18z"/>
                <path d="M2044 83 c-7 -2 -10 -12 -7 -22 4 -15 -1 -17 -30 -15 -21 2 -40 -3
-47 -11 -9 -11 -6 -14 19 -13 17 1 31 5 31 9 0 5 6 6 14 3 8 -3 19 3 26 16 13
24 10 39 -6 33z"/>
            </g>

        </svg>
    );
};
